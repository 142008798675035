/* eslint-disable react/prop-types */
import React from "react";
import * as importedStyles from "./EmailInput.module.scss";
import ShadowInput from "../../components/UI/TextField";
import useEmailValidation from "./useEmailValidation";

const styles = importedStyles.default || importedStyles;

const EmailInput = ({ userEmail, onChangeEmail }) => {
  const { error, handleFocus, handleBlur, setTouched } =
    useEmailValidation(userEmail);

  const handleEmailChange = (value) => {
    onChangeEmail(value);
    setTouched(true);
  };

  return (
    <div
      className={styles.addressContainer}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <label
        htmlFor="address"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className={styles.addressLabel}>Email address</p>
        </div>
        <ShadowInput
          value={userEmail}
          placeholder="Enter email address"
          onChange={handleEmailChange}
          style={{ borderColor: error ? "rgb(211, 47, 47)" : undefined }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </label>
      {error && <p className={styles.error_message}>{error}</p>}
    </div>
  );
};

export default EmailInput;
