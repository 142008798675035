/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import { createStyles } from "./styles";

/**
 * ShadowInput component - Renders an isolated input element using Shadow DOM to encapsulate styles.
 * This approach prevents CSS leakage and interference from external styles, maintaining a consistent look.
 *
 * Props:
 * - `value` (string): The current value of the input.
 * - `placeholder` (string): Placeholder text to display when the input is empty.
 * - `onChange` (function): Callback function triggered when the input value changes.
 * - `onBlur` (function): Callback function triggered when the input loses focus.
 * - `disabled` (boolean): If true, the input is disabled.
 * - `type` (string): The type of the input (e.g., "text", "email"). Defaults to "text".
 * - `style` (object): Inline styles applied to the input, e.g., for setting border color.
 */

const ShadowInput = (props) => {
  const {
    value,
    placeholder,
    onChange,
    disabled,
    type = "text",
    style,
    onBlur,
    onFocus,
    innerRef
  } = props;
  // Refs to access the container div (for attaching shadow DOM) and input element (for setting properties)
  const inputContainer = useRef(null);
  const inputElement = useRef(null);

  // Effect to set up Shadow DOM and initialize input element
  useEffect(() => {
    // Only initialize shadow DOM if it hasn't been created already
    if (inputContainer.current && !inputContainer.current.shadowRoot) {
      // Create an open shadow root for isolated styling
      const shadowRoot = inputContainer.current.attachShadow({ mode: "open" });
      // Append custom styles to shadow root for encapsulation
      shadowRoot.appendChild(createStyles());

      // Create and configure the input element within shadow DOM
      inputElement.current = document.createElement("input");
      inputElement.current.type = type;
      inputElement.current.placeholder = placeholder;
      inputElement.current.disabled = disabled;

      if (innerRef) {
        innerRef.current = inputElement.current;
      }

      // Set initial value and define input event handlers
      inputElement.current.value = value;
      inputElement.current.oninput = (e) => onChange(e.target.value); // Trigger onChange with new value
      inputElement.current.onblur = onBlur; // Trigger onBlur when input loses focus
      inputElement.current.onfocus = onFocus;

      // Append the configured input element to shadow DOM
      shadowRoot.appendChild(inputElement.current);
    }
  }, [placeholder, disabled, type]); // Re-run only if placeholder, disabled, or type changes

  // Effect to update input value and style dynamically as props change
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.value = value || ""; // Update input value with latest prop
      inputElement.current.style.borderColor = style?.borderColor || ""; // Apply border color if provided
    }
  }, [value, style]); // Re-run whenever value or style changes

  // Render an empty div to contain the shadow DOM
  return <div ref={inputContainer}></div>;
};

export default ShadowInput;
