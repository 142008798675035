/* eslint-disable react/prop-types */
import React from "react";
import * as importedStyles from "./AddressInput.module.scss";

const styles = importedStyles.default || importedStyles;

const AddressInput = ({ setAddress }) => {
  return (
    <div
      className={styles.addressContainer}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <label
        htmlFor="address"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className={styles.addressLabel}>
            Subject Address or Point of Interest
          </p>
        </div>
        <input
          type="text"
          className={styles.emailInput}
          id="address"
          placeholder="Enter a location"
          //value={userEmail}
          onChange={(e) => setAddress(e.target.value)}
          //onBlur={validateEmail}
          // style={{
          //   borderColor: error ? "rgb(211, 47, 47)" : "#b3bcc9"
          // }}
        />
      </label>
      {/* {error && <p className={styles.error_message}>{error}</p>}{" "} */}
    </div>
  );
};

export default AddressInput;
