import { useMemo } from "react";
// import { ReactComponent as MarkerSVG } from '../../../assets/icons/Meter.svg';
import RentMeterSVG from "../../assets/images/RentMeter.svg";
import Arrow from "../../assets/images/rent-arrow.svg";

import * as importedStyles from "./RentMeter.module.scss";

const styles = importedStyles.default || importedStyles;

const RentMeter = (outputData) => {
  const calculatePercentile = (min, max, percentage) => {
    return (max - min) * percentage + min;
  };

  // function calculateAngle(percentage) {
  //   const minAngle = -13; // Angle for 0%
  //   const maxAngle = 195; // Angle for 100%
  //   const midAngle = 90; // Angle for 50%

  //   // Calculate the angle based on the percentage using a linear interpolation
  //   if (percentage <= 50) {
  //     return minAngle + (midAngle - minAngle) * (percentage / 50);
  //   } else {
  //     return midAngle + (maxAngle - midAngle) * ((percentage - 50) / 50);
  //   }
  // }

  const memoizedValues = useMemo(() => {
    //let arrowToMin = false;
    //let arrowToMax = false;
    let resultDescription = "";
    let resultTitle = "";
    //let yourRentPrice = totalPay;
    let primary_percentile_05 = outputData.mean - 1.645 * outputData.std_dev;
    let primary_percentile_95 = outputData.mean + 1.645 * outputData.std_dev;

    let percentile_05 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.05
    );
    let percentile_25 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.25
    );
    let percentile_75 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.75
    );
    let percentile_95 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.95
    );

    return {
      //arrowAngleValue,
      resultDescription,
      resultTitle,
      //rentPercentage,
      percentile_05,
      percentile_25,
      percentile_75,
      percentile_95
    };
  }, [outputData.data]);

  return (
    <>
      <div className={styles.resultMainMeter2}>
        <div className={styles.indicator}>
          <div className={styles.indicatorBox}>
            <span className={styles.min}>
              ${Math.round(memoizedValues.percentile_05)?.toLocaleString()}
            </span>
            <span className={styles.max}>
              ${Math.round(memoizedValues.percentile_95)?.toLocaleString()}
            </span>
            <span className={styles.percentile25}>
              ${Math.round(memoizedValues.percentile_25)?.toLocaleString()}
            </span>
            <span className={styles.percentile75}>
              ${Math.round(memoizedValues.percentile_75)?.toLocaleString()}
            </span>
            {/* <span className="average-rent">
          ${'150'.toLocaleString()}
        </span> */}
            <span
              className="your-rent green"
              style={
                {
                  // left: value <= 50 ? "-20px" : "auto",
                  // right: value <= 50 ? "auto" : "-20px"
                }
              }
            >
              {/* ${'750'?.toLocaleString()} */}
            </span>
            <span
              className={styles.rentArrow}
              style={{
                transform: `${
                  outputData.mean ? "rotate(90deg)" : "rotate(0deg)"
                }`
              }}
            >
              <img src={Arrow} alt="meter arrow" />
              {/* <div
            className="rent-line"
            style={{ display: `${true ? 'block' : 'none'}` }}></div> */}
            </span>
            <img
              src={RentMeterSVG}
              alt="meter"
              // className={
              //   true
              //     ? 24 <= 25
              //       ? "animated-pink"
              //       : 100 >= 75
              //       ? "animated-green"
              //       : ""
              //     : ""
              // }
            />
          </div>
          {/* <div className="indicator-colors">
        <div className="indicator-color-title">
          <h5 className="indicator-color-budget">Your Budget</h5>
          <div className="indicator-color-desc">
            For this area, your budget is
          </div>
        </div>
        <div className="indicator-color">
          <div className="color-box"></div>
          Too low
        </div>
        <div className="indicator-color">
          <div className="color-box"></div>
          Reasonable
        </div>
        <div className="indicator-color">
          <div className="color-box"></div>
          Great
        </div>
      </div> */}
        </div>
        {/* <div className="resultSummaryData">
      Results based on {fetchResponse.samples}, {fetchResponse.bedrooms}-bedroom
      rentals seen within
      <br /> 12 months within a {fetchResponse.radius_miles} mile radius
    </div> */}
      </div>
    </>
  );
};

export default RentMeter;
