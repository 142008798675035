export const createStyles = () => {
  const style = document.createElement("style");
  style.textContent = `
  input {
    width: 100%;
    padding: 10px 14px 9px;
    border: 1px solid #b3bcc9;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    color: #2d3035;
    font-weight: 400;
    font-weight: 500;
    font-size: 14px;
  }
    `;
  return style;
};
