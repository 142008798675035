import { createAction } from "redux-actions";

export const GET_RENT_ANALYSIS_REQUEST = "GET_RENT_ANALYSIS_REQUEST";
export const GET_RENT_ANALYSIS_SUCCESS = "GET_RENT_ANALYSIS_SUCCESS";
export const GET_RENT_ANALYSIS_FAILURE = "GET_RENT_ANALYSIS_FAILURE";
export const RESET_ERROR = "RESET_ERROR";

export const RESET_RENT_ANALYSIS = "RESET_RENT_ANALYSIS";

export const SET_API_KEY = "SET_API_KEY";

export const getRentAnalysisRequest = createAction(GET_RENT_ANALYSIS_REQUEST);
export const getRentAnalysisSuccess = createAction(GET_RENT_ANALYSIS_SUCCESS);
export const getRentAnalysisFailure = createAction(GET_RENT_ANALYSIS_FAILURE);

export const resetRentAnalysis = createAction(RESET_RENT_ANALYSIS);

export const setApiKey = createAction(SET_API_KEY);

export const SET_API_HOST = "SET_API_HOST";

export const setApiHost = createAction(SET_API_HOST);

export const SET_CAPTCHA = "SET_CAPTCHA";
export const setCaptcha = createAction(SET_CAPTCHA);
