/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import * as importedStyles from "./FrameWrapper.module.scss";
import useWindowHeight from "../../hooks/useWindowHeight";

const styles = importedStyles.default || importedStyles;

export const FrameWrapper = ({ children, apikey, closeModal, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Check if Safari browser
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    setIsSafari(isSafariBrowser);
  }, []);

  const isWide = useWindowHeight();

  useEffect(() => {
    const iframeDocument = contentRef?.contentWindow?.document;
    if (iframeDocument && isIframeLoaded) {
      const parentStylesheets = Array.from(document.styleSheets);
      const parentInlineStyles = Array.from(
        document.querySelectorAll("style")
      ).map((style) => style.innerHTML);

      parentStylesheets.forEach((stylesheet) => {
        const newStylesheet = iframeDocument.createElement("link");
        newStylesheet.rel = "stylesheet";
        newStylesheet.href = stylesheet.href;
        iframeDocument.head.appendChild(newStylesheet);
      });

      parentInlineStyles.forEach((inlineStyle) => {
        const newStyle = iframeDocument.createElement("style");
        newStyle.innerHTML = inlineStyle;
        iframeDocument.head.appendChild(newStyle);
      });

      const iframeBody = iframeDocument.body;
      if (iframeBody) {
        iframeBody.style.display = "flex";
        iframeBody.style.alignItems = isWide > 800 ? "center" : "";
        iframeBody.style.justifyContent = "center";
        iframeBody.style.height = "100vh";
      }

      const overlay = document.createElement("div");
      overlay.style.position = "fixed";
      overlay.style.top = "0";
      overlay.style.left = "0";
      overlay.style.width = "100%";
      overlay.style.height = "100%";
      overlay.style.background = "rgba(22.3%, 58.8%, 82.4%, 0.9)";
      overlay.style.zIndex = "1000";
      overlay.addEventListener("click", (event) => {
        event.stopPropagation();
        closeModal();
      });
      iframeBody.appendChild(overlay);

      setLoading(false);
    }
  }, [contentRef, isIframeLoaded, isWide]);

  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <div>
      <iframe
        //key={isIframeLoaded ? "loaded" : "loading"}
        //key={JSON.stringify(props)}
        key={
          isSafari
            ? isIframeLoaded
              ? "loaded"
              : "loading"
            : JSON.stringify(props)
        }
        {...props}
        ref={setContentRef}
        className={styles.iframeWrapper}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        onLoad={() => {
          setTimeout(() => {
            setIsIframeLoaded(true);
          }, 200);
        }}
        style={{ display: loading ? "none" : "block" }}
      >
        {mountNode &&
          createPortal(React.cloneElement(children, { apikey }), mountNode)}
      </iframe>
    </div>
  );
};
