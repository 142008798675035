/* eslint-disable react/prop-types */
import React, { useState } from "react";
import * as importedStyles from "./index.module.scss";
import useMediaQuery from "../../hooks/useMediaQuery";
import HouseIcon from "../../assets/images/house_small.svg";
import ApartIcon from "../../assets/images/apartments_small.svg";

const styles = importedStyles.default || importedStyles;

const Tooltip = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isSmallDevice = useMediaQuery("(max-width: 379px)");
  const [visible, setVisible] = useState(false);

  const toggleTooltip = () => setVisible(!visible);
  const showTooltip = () => !isMobile && setVisible(true);
  const hideTooltip = () => !isMobile && setVisible(false);

  const handleCloseTooltip = () => {
    visible && setVisible(false);
  };

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onClick={isMobile ? toggleTooltip : undefined}
    >
      <svg
        className={`${styles.icon} ${visible ? styles.iconActive : ""}`}
        width="14"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
          fill="currentColor"
        />
        <path
          d="M10.7002 11.6771C10.5866 12.0877 10.2577 12.4367 9.83175 12.4367C9.39822 12.4367 9.03546 12.0802 9.10315 11.652C9.11794 11.5584 9.1355 11.4705 9.15581 11.3883C9.22558 11.1016 9.34186 10.8417 9.50465 10.6088C9.67209 10.3758 9.89302 10.1294 10.1674 9.86952C10.3814 9.67239 10.5744 9.48646 10.7465 9.31172C10.9186 9.13251 11.0558 8.94434 11.1581 8.74721C11.2605 8.5456 11.3116 8.31486 11.3116 8.055C11.3116 7.77275 11.2628 7.53305 11.1651 7.33592C11.0674 7.13879 10.9233 6.9887 10.7326 6.88565C10.5465 6.7826 10.314 6.73108 10.0349 6.73108C9.80233 6.73108 9.58372 6.77588 9.37907 6.86549C9.17442 6.95061 9.0093 7.08502 8.88372 7.26871C8.64353 7.61147 8.34892 7.98108 7.93039 7.98108H7.86084C7.38772 7.98108 6.98597 7.58353 7.13263 7.13371C7.20135 6.92295 7.29668 6.7305 7.4186 6.55635C7.68837 6.17104 8.05116 5.88207 8.50698 5.68941C8.96279 5.49676 9.47209 5.40043 10.0349 5.40043C10.6581 5.40043 11.1907 5.50348 11.6326 5.70957C12.0744 5.91119 12.4116 6.20689 12.6442 6.59667C12.8814 6.98198 13 7.44793 13 7.99452C13 8.38879 12.9186 8.74721 12.7558 9.06979C12.593 9.38789 12.3814 9.68583 12.1209 9.96361C11.8605 10.2369 11.5791 10.5102 11.2767 10.7835C11.0163 11.012 10.8395 11.2606 10.7465 11.5295C10.7296 11.5774 10.7142 11.6267 10.7002 11.6771ZM8.90465 14.5335C8.90465 14.2916 8.9907 14.0877 9.16279 13.9219C9.33488 13.7517 9.56977 13.6666 9.86744 13.6666C10.1651 13.6666 10.4 13.7517 10.5721 13.9219C10.7442 14.0877 10.8302 14.2916 10.8302 14.5335C10.8302 14.7754 10.7442 14.9815 10.5721 15.1518C10.4 15.3175 10.1651 15.4004 9.86744 15.4004C9.56977 15.4004 9.33488 15.3175 9.16279 15.1518C8.9907 14.9815 8.90465 14.7754 8.90465 14.5335Z"
          fill="currentColor"
        />
      </svg>

      {/* {visible && (
        <div className={styles.tooltipText}>
          <div className={styles.tooltipHeader}>Building Type Options</div>
          <div
            style={{
              padding: "5px 10px",
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  position: "relative"
                }}
              >
                {" "}
                <div style={{ position: "absolute", bottom: "3px" }}>
                  <img src={ApartIcon} alt="apartments" />{" "}
                </div>
                <p style={{ marginLeft: "10px", paddingLeft: "15px" }}>
                  Apt: Includes apartments,
                </p>{" "}
              </div>
              <p>condos and townhomes.</p>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  position: "relative"
                }}
              >
                {" "}
                <div style={{ position: "absolute", bottom: "3px" }}>
                  <img src={HouseIcon} alt="house" />{" "}
                </div>
                <p style={{ marginLeft: "10px", paddingLeft: "15px" }}>
                  House: Includes single-family
                </p>{" "}
              </div>
              <p>and duplexes.</p>
            </div>
          </div>
        </div>
      )} */}
      {visible && (
        <div
          className={styles.upArrow}
          style={{
            width: !isMobile ? "284px" : isSmallDevice ? "140px" : "200px"
          }}
          onClick={handleCloseTooltip}
        >
          <div style={{ marginBottom: "18px" }} className={styles.headerText}>
            <p>Building Type Options</p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div style={{ display: "flex", alignItems: "start", gap: "8px" }}>
              <img src={ApartIcon} alt="house" />{" "}
              <p className={styles.contentText}>
                Apt: Includes apartments, condos and townhomes.
              </p>{" "}
            </div>
            <div style={{ display: "flex", alignItems: "start", gap: "8px" }}>
              <img src={HouseIcon} alt="house" />{" "}
              <p className={styles.contentText}>
                House: Includes single-family and duplexes.
              </p>{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
