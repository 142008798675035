/* eslint-disable react/prop-types */
import * as importedStyles from "./CustomGoogleAutocomplete.module.scss";
const styles = importedStyles.default || importedStyles;
import { Oval } from "react-loader-spinner";

const GeolocationMessage = ({ geolocationStatusMessage }) => {
  return (
    <div>
      {geolocationStatusMessage === "loading" ? (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span className={styles.loadingText}>Loading</span>
          <Oval
            height={15}
            width={15}
            color="rgb(25, 111, 175)"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#ffffff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : geolocationStatusMessage === "error" ? (
        <p className={styles.errorText}>
          Unable to access your browser&apos;s Location Services
        </p>
      ) : (
        <span className={styles.loadingText}>{geolocationStatusMessage}</span>
      )}
    </div>
  );
};

export default GeolocationMessage;
