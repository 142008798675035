import {
  GET_RENT_ANALYSIS_REQUEST,
  GET_RENT_ANALYSIS_SUCCESS,
  GET_RENT_ANALYSIS_FAILURE,
  RESET_RENT_ANALYSIS,
  SET_API_KEY,
  SET_API_HOST,
  SET_CAPTCHA,
  RESET_ERROR
} from "../actions";

const initialState = {
  outputData: [],
  analysisIsReady: false,
  error: "",
  apikey: "",
  apihost: "",
  captcha: false,
  isLoading: false
};
export const generalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_API_KEY:
      return {
        ...state,
        apikey: payload // Set the apikey in the state
      };

    case SET_API_HOST:
      return {
        ...state,
        apihost: payload // Set the apihost in the state
      };

    case SET_CAPTCHA:
      return {
        ...state,
        captcha: true
      };
    case GET_RENT_ANALYSIS_REQUEST:
      return {
        ...state,
        outputData: [],
        analysisIsReady: false,
        isLoading: true
      };
    case GET_RENT_ANALYSIS_SUCCESS:
      return {
        ...state,
        outputData: payload,
        analysisIsReady: true,
        error: "",
        isLoading: false
      };
    case GET_RENT_ANALYSIS_FAILURE:
      return {
        ...state,
        error: payload || "Request to API was failed",
        isLoading: false
      };

    case RESET_RENT_ANALYSIS:
      return {
        ...state,
        outputData: [],
        analysisIsReady: false,
        isLoading: false
      };

    case RESET_ERROR:
      return {
        ...state,
        error: ""
      };

    // case SET_API_KEY:
    //   return {
    //     ...state,
    //     apikey: payload
    //   };
    default:
      return state;
  }
};
