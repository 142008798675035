/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetRentAnalysis } from "../../store/actions";

import * as importedStyles from "./OutputPage.module.scss";
import Footer from "../../components/Footer/Footer";

import addressIcon from "../../assets/images/address.svg";
import RentMeter from "../../components/RentMeter/RentMeter";
import PropTypes from "prop-types";

const styles = importedStyles.default || importedStyles;

const OutputPage = ({ closeModal, setIsShowCheckLoader }) => {
  const { outputData } = useSelector((state) => state.generalReducer);
  const [pageLoadTime] = useState(Date.now());

  const dispatch = useDispatch();

  const handleCloseOutputPage = () => {
    const endTime = Date.now();
    const time = (endTime - pageLoadTime) / 1000;
    time < 2 && setIsShowCheckLoader(true);
    dispatch(resetRentAnalysis());
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const values = [
    {
      label: "average",
      name: "AVERAGE",
      price:
        outputData?.mean?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0
        }) || 0
    },
    {
      label: "median",
      name: "MEDIAN",
      price:
        outputData?.median?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0
        }) || 0
    },
    {
      label: "25th",
      name: "25th %",
      price:
        outputData?.percentile_25?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0
        }) || 0
    },
    {
      label: "75th",
      name: "75th %",
      price:
        outputData?.percentile_75?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0
        }) || 0
    }
  ];

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className={styles.output_page_container}>
      <div onClick={closeModal} className={styles.btnClose}></div>
      <div>
        <h2 className={styles.title_container}>QuickView Rent Estimate</h2>
      </div>
      <div className={styles.header_container}>
        <img src={addressIcon} alt="LocationSvg" />
        <h2>{outputData?.address}</h2>
      </div>

      <div className={styles.content_container}>
        <div className={styles.meterBox}>
          <RentMeter {...outputData} />
        </div>

        <div className={styles.resultsBox}>
          {values &&
            values.map((item) => (
              <div key={item.label} className={styles.resultsBoxItem}>
                <p className={styles.text}>{item.name}</p>
                <p className={styles.value}>{`${
                  item.price >= 1000
                    ? formatNumberWithCommas(item.price)
                    : item.price
                }`}</p>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.details}>
        Results based on {outputData?.samples},{" "}
        {outputData.bedrooms && outputData.bedrooms > 0
          ? `${outputData.bedrooms}-bedroom`
          : `studio`}
        , {outputData.building_type === "Apartment" && "Apartment "}
        {outputData.building_type === "House" && "House "}
        {outputData.baths === "1 only" && "single bath "}
        {outputData.baths === "1.5+" && "1½ or more bath "}
        rentals seen within{" "}
        {outputData?.look_back_days
          ? Math.floor(outputData?.look_back_days / 30)
          : 0}{" "}
        months in a {outputData?.radius_miles} mile radius
      </div>

      <div className={styles.button_container}>
        <button onClick={handleCloseOutputPage}>
          Run Another Rent Estimate
        </button>
      </div>

      <div className={styles.policyContainer}>
        <p className={styles.policy}>
          By clicking the button above you are agreeing to our{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.rentometer.com/terms-of-use"
            className={styles.policyBold}
          >
            Terms of Use{" "}
          </a>
          &{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.rentometer.com/privacy-policy"
            className={styles.policyBold}
          >
            Privacy Policy
          </a>
        </p>
      </div>
      <Footer isShowDate={true} />
    </div>
  );
};

OutputPage.propTypes = {
  closeModal: PropTypes.any,
  setIsShowCheckLoader: PropTypes.any
};

export default OutputPage;
