/* eslint-disable react/prop-types */
import React from "react";
import * as importedStyles from "./Footer.module.scss";
import Logo from "../../assets/images/logo.svg";
import { formatDateTime } from "../../utils/helper-functions/formatDateTime";

const styles = importedStyles.default || importedStyles;

const Footer = (props) => {
  const current_date = formatDateTime();
  return (
    <div className={styles.footer_container}>
      <div className={styles.footer_container_flex}>
        <p className={styles.footer_text}>Powered by</p>

        <a href="https://www.rentometer.com/" target="_blank" rel="noreferrer">
          <img
            // src="https://res.cloudinary.com/dqrg6ysyb/image/upload/v1685009338/RentometrLogo_ogma9n.svg"
            src={Logo}
            alt="RentometrLogoSvg"
          />
        </a>
      </div>

      {props.isShowDate && <p className={styles.footer_date}>{current_date}</p>}
    </div>
  );
};

export default Footer;
