/* eslint-disable react/prop-types */
import React from "react";
import { Provider } from "react-redux";

import configureStore from "./store/store";
import { Routes } from "./route/Routes";
const { store } = configureStore();

const App = ({
  apikey,
  buttonTitle,
  apihost,
  enableCaptcha,
  enableEmailReporting,
  useAutocomplete = true,
  googleApiKey
}) => {
  return (
    <Provider store={store}>
      <Routes
        buttonTitle={buttonTitle}
        apikey={apikey}
        apihost={apihost}
        enableCaptcha={enableCaptcha}
        enableEmailReporting={enableEmailReporting}
        useAutocomplete={useAutocomplete}
        googleApiKey={googleApiKey}
      />
    </Provider>
  );
};

export default App;
