import { call, put, takeEvery } from "redux-saga/effects";
import { getRentAnalysis } from "../../api";
import {
  GET_RENT_ANALYSIS_REQUEST,
  getRentAnalysisSuccess,
  getRentAnalysisFailure
} from "../actions";

function* getRentAnalysisSaga(action) {
  const {
    apikey,
    address,
    bedrooms,
    baths,
    building_type,
    apihost,
    userEmail
  } = action.payload;
  const uriString =
    `&address=${address}&bedrooms=${bedrooms}&baths=${baths}&building_type=${building_type}` +
    (userEmail ? `&userEmail=${userEmail}` : "");
  try {
    const response = yield call(
      getRentAnalysis,
      apikey,
      uriString,
      apihost,
      userEmail
    );
    yield put(getRentAnalysisSuccess(response?.data));
  } catch (error) {
    yield put(getRentAnalysisFailure(error?.response?.data?.errors[0]));
  }
}

function* watchGeneralSaga() {
  yield takeEvery(GET_RENT_ANALYSIS_REQUEST, getRentAnalysisSaga);
}
export default watchGeneralSaga;
