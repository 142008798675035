/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getRentAnalysisRequest } from "../../store/actions";
import * as importedStyles from "./InputPage.module.scss";
import Footer from "../../components/Footer/Footer";
import useMediaQuery from "../../hooks/useMediaQuery";
import CustomGoogleAutocomplete from "../../components/Autocomplete/CustomGoogleAutocomplete.component";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import Captcha from "./Captcha";
import FormField from "../../components/FormField/FormField";
import { EmailInput } from "../../features/emailInput";
import AddressInput from "../../components/AddressInput/AddressInput";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Tooltip } from "../../features/tooltip";

const styles = importedStyles.default || importedStyles;

const InputPage = ({
  closeModal,
  isShowCheckLoader,
  enableEmailReporting,
  useAutocomplete,
  googleApiKey
}) => {
  const isEnableEmailCollectingFeature = enableEmailReporting;

  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [baths, setBaths] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [referrer, setReferrer] = useState("");
  const { error, apihost, apikey, captcha } = useSelector(
    (state) => state.generalReducer
  );
  const [clearAddress, setClearAddress] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [, setElapsedTime] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const { isLoading } = useSelector((state) => state.generalReducer);
  const [userEmail, setUserEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    const emailFromCookies = Cookies.get("userEmail");
    if (emailFromCookies) {
      setIsEmailValid(true);
      setUserEmail(emailFromCookies);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("testMode")) {
      console.log(`startTime=${startTime}`);
    }
  }, [startTime]);

  const startTimer = () => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
  };

  useEffect(() => {
    if (
      address &&
      bedrooms &&
      baths &&
      buildingType &&
      buildingType !== " " &&
      startTime
    ) {
      const endTime = Date.now();
      if (localStorage.getItem("testMode")) {
        console.log(`endTime=${endTime}`);
      }
      setElapsedTime((endTime - startTime) / 1000);
    }
  }, [address, bedrooms, baths, buildingType, startTime]);

  const handleBedrooms = (event) => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
    setBedrooms(event.target.value);
  };

  const handleBaths = (event) => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
    setBaths(event.target.value);
  };

  const handleBuildingType = (event) => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
    setBuildingType(event.target.value);
  };

  const handleReferrer = (event) => {
    setReferrer(event.target.value);
  };

  const handleChangeEmail = (email) => {
    setUserEmail(email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  useEffect(() => {
    if (captchaVerified) {
      dispatchSubmit();
    }
  }, [captchaVerified]);

  const handleSubmit = () => {
    if (referrer) {
      console.log("Suspicious activity detected. Submission blocked.");
      return;
    }

    const endTime = Date.now();
    const time = localStorage.getItem("fillTime")
      ? Number(localStorage.getItem("fillTime"))
      : 2;

    if (address) {
      const calculatedElapsedTime = (endTime - startTime) / 1000;
      console.log(`result====${calculatedElapsedTime}`);

      setElapsedTime(calculatedElapsedTime); // Оновлюємо elapsedTime

      if (calculatedElapsedTime < time && captcha) {
        setShowCaptcha(true);
        setIsRequestPending(true);
      } else {
        dispatchSubmit();
      }
    }
  };

  const handleAddEmailToCookies = () => {
    Cookies.set("userEmail", userEmail, { expires: 1 });
  };

  const dispatchSubmit = () => {
    dispatch(
      getRentAnalysisRequest({
        apikey,
        apihost,
        address,
        bedrooms,
        baths,
        building_type: buildingType,
        userEmail
      })
    );
    handleAddEmailToCookies();
    setIsRequestPending(false);
    setShowCaptcha(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isUseAutocompleteByGoogle = useAutocomplete;

  return (
    <div className={styles.input_page_container}>
      {isShowCheckLoader && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
      <div onClick={closeModal} className={styles.btnClose}></div>
      <h2>QuickView Rent Estimate</h2>

      {isUseAutocompleteByGoogle ? (
        <div className={styles.address_box}>
          <CustomGoogleAutocomplete
            setAddress={setAddress}
            clearAddress={clearAddress}
            setClearAddress={setClearAddress}
            isDisabled={false}
            setStartTime={setStartTime}
            onFocus={startTimer}
            googleApiKey={googleApiKey}
          />
        </div>
      ) : (
        <div className={styles.address_box}>
          <AddressInput setAddress={setAddress} />
        </div>
      )}

      {isEnableEmailCollectingFeature && (
        <div className={styles.address_box}>
          <EmailInput userEmail={userEmail} onChangeEmail={handleChangeEmail} />
        </div>
      )}

      {isMobile ? (
        <div className={styles.propertiesSection}>
          <div className={styles.sectionType}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px"
              }}
            >
              <p className={styles.title}>Bedrooms</p>
            </div>

            <div
              aria-label="bedrooms"
              name="bedrooms"
              value="1"
              onChange={handleBedrooms}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                width: "100%",
                gap: "10px"
              }}
            >
              <FormField
                content="Studio"
                width="100%"
                minWidth="94px"
                isActive={bedrooms === "0"}
                action={() => setBedrooms("0")}
              />
              <FormField
                content="1"
                width="100%"
                isActive={bedrooms === "1"}
                action={() => setBedrooms("1")}
              />
              <FormField
                content="2"
                width="100%"
                isActive={bedrooms === "2"}
                action={() => setBedrooms("2")}
              />
              <FormField
                content="3"
                width="100%"
                isActive={bedrooms === "3"}
                action={() => setBedrooms("3")}
              />
              <FormField
                content="4"
                width="100%"
                isActive={bedrooms === "4"}
                action={() => setBedrooms("4")}
              />
            </div>
          </div>

          <div className={styles.sectionType}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px"
              }}
            >
              <p className={styles.title}>Bathrooms (optional)</p>
            </div>

            <div
              aria-label="bathroomType"
              name="bathroomType"
              value={baths}
              onChange={handleBaths}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "10px"
              }}
            >
              <FormField
                content="All"
                width="100%"
                isActive={baths === ""}
                action={() => setBaths("")}
              />
              <FormField
                content="1"
                width="100%"
                isActive={baths === "1"}
                action={() => setBaths("1")}
              />
              <FormField
                content="1+"
                width="100%"
                isActive={baths === "1.5"}
                action={() => setBaths("1.5")}
              />
            </div>
          </div>

          <div className={styles.sectionType}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px",
                position: "relative"
              }}
            >
              <p className={styles.title}>Building type</p>
              <div style={{ position: "absolute", left: "87px", top: -5 }}>
                <Tooltip />
              </div>
            </div>
            <div
              aria-label="propertyType"
              name="propertyType"
              value={buildingType}
              onChange={handleBuildingType}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px"
              }}
            >
              <FormField
                content="All"
                width="100%"
                isActive={buildingType === ""}
                action={() => setBuildingType("")}
              />
              <FormField
                content="House"
                width="100%"
                isActive={buildingType === "house"}
                action={() => setBuildingType("house")}
              />
              <FormField
                content="Apartment"
                width="100%"
                isActive={buildingType === "apartment"}
                action={() => setBuildingType("apartment")}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.propertiesSection}>
          <div className={styles.sectionType}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px"
              }}
            >
              <p className={styles.title}>Number of bedrooms</p>
            </div>

            <div
              aria-label="bedrooms"
              name="bedrooms"
              value="1"
              onChange={handleBedrooms}
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                //width: "100%",
                gap: isMobile ? "0" : "8px"
              }}
            >
              <FormField
                content="Studio"
                width="80px"
                isActive={bedrooms === "0"}
                action={() => setBedrooms("0")}
              />
              <FormField
                content="1"
                width="54px"
                isActive={bedrooms === "1"}
                action={() => setBedrooms("1")}
              />
              <FormField
                content="2"
                width="54px"
                isActive={bedrooms === "2"}
                action={() => setBedrooms("2")}
              />
              <FormField
                content="3"
                width="54px"
                isActive={bedrooms === "3"}
                action={() => setBedrooms("3")}
              />
              <FormField
                content="4"
                width="54px"
                isActive={bedrooms === "4"}
                action={() => setBedrooms("4")}
              />
            </div>
          </div>

          <div className={styles.sectionType}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px"
              }}
            >
              <p className={styles.title}>Number of bathrooms (optional)</p>
            </div>

            <div
              aria-label="bathroomType"
              name="bathroomType"
              value={baths}
              onChange={handleBaths}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px"
              }}
            >
              <FormField
                content="All"
                width="79px"
                isActive={baths === ""}
                action={() => setBaths("")}
              />
              <FormField
                content="1"
                width="79px"
                isActive={baths === "1"}
                action={() => setBaths("1")}
              />
              <FormField
                content="1+"
                width="79px"
                isActive={baths === "1.5"}
                action={() => setBaths("1.5")}
              />
            </div>
          </div>

          <div className={styles.sectionType}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px",
                position: "relative"
              }}
            >
              <p className={styles.title}>Building type</p>
              <div style={{ position: "absolute", left: "100px", top: -5 }}>
                <Tooltip />
              </div>
            </div>
            <div
              aria-label="propertyType"
              name="propertyType"
              value={buildingType}
              onChange={handleBuildingType}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                flexWrap: isMobile ? "wrap" : "nowrap"
              }}
            >
              <FormField
                content="All"
                width="80px"
                isActive={buildingType === ""}
                action={() => setBuildingType("")}
              />
              <FormField
                content="House"
                width="116px"
                isActive={buildingType === "house"}
                action={() => setBuildingType("house")}
              />
              <FormField
                content="Apartment"
                width="116px"
                isActive={buildingType === "apartment"}
                action={() => setBuildingType("apartment")}
              />
            </div>
          </div>

          <div className={styles.sectionType} style={{ display: "none" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "14px",
                gap: "6px"
              }}
            >
              <p className={styles.title}>Referrer</p>
            </div>
            <div
              aria-label="referrer"
              name="referrer"
              value={referrer}
              onChange={handleReferrer}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                flexWrap: isMobile ? "wrap" : "nowrap"
              }}
            >
              <input
                type="text"
                id="referrer"
                name="referrer"
                value={referrer}
                onChange={handleReferrer}
                onFocus={startTimer}
              />
            </div>
          </div>
        </div>
      )}

      <div className={styles.button_container}>
        <div className={styles.button_containerContent}>
          <button
            disabled={
              !address.length ||
              buildingType === " " ||
              !bedrooms ||
              isRequestPending ||
              (isEnableEmailCollectingFeature && (!userEmail || !isEmailValid))
            }
            onClick={handleSubmit}
            className={styles.buttonload}
          >
            {isLoading ? (
              <div className={styles.loaderBox}>
                <Oval
                  height={20}
                  width={20}
                  color="#d95d1a"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
                Loading
              </div>
            ) : (
              "Get Rent Estimate"
            )}
          </button>

          <div
            style={{
              display: showCaptcha ? "flex" : "none",
              zIndex: "99999999999999999",
              position: "relative"
            }}
          ></div>
          {showCaptcha && captcha && (
            <div style={{ paddingTop: "20px" }}>
              <Captcha setCaptchaVerified={setCaptchaVerified} />
            </div>
          )}
          {error && <span className={styles.error}>{error}</span>}
        </div>
        <p className={styles.policy}>
          By clicking the button above you are agreeing to our{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.rentometer.com/terms-of-use"
            className={styles.policyBold}
          >
            Terms of Use{" "}
          </a>
          &{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.rentometer.com/privacy-policy"
            className={styles.policyBold}
          >
            Privacy Policy
          </a>
        </p>
      </div>
      <Footer />
    </div>
  );
};

InputPage.propTypes = {
  closeModal: PropTypes.any,
  isShowCheckLoader: PropTypes.any
};

export default InputPage;
