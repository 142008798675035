/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "../../hooks/useMediaQuery";
import * as importedStyles from "./CustomGoogleAutocomplete.module.scss";
import LocationIcon from "../../assets/images/location-icon.svg";
import GeolocationMessage from "./GeolocationMessage";
import { Oval } from "react-loader-spinner";
import ShadowInput from "../UI/TextField";

const styles = importedStyles.default || importedStyles;

const options = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: Infinity
};

const CustomGoogleAutocomplete = ({
  clearAddress,
  setClearAddress,
  setAddress,
  isDisabled,
  onFocus,
  setStartTime
  // googleApiKey
}) => {
  const [, setAutocomplete] = useState(null);
  const [geolocation, setGeolocation] = useState({});
  const [geolocationStatus, setGeolocationStatus] = useState({
    status: false,
    message: ""
  });
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      const autocompleteInstance = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "us" },
          types: ["geocode"]
        }
      );

      autocompleteInstance.addListener("place_changed", () => {
        const place = autocompleteInstance.getPlace();
        if (place && place.formatted_address) {
          inputRef.current.value = place.formatted_address;
          setAddress(place.formatted_address);
        }
      });

      const latitude = geolocation.latitude;
      const longitude = geolocation.longitude;
      if (latitude && longitude) {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            const address =
              results !== null
                ? results[0].formatted_address
                : "Address not found";
            autocompleteInstance.setFields(["formatted_address"]);
            autocompleteInstance.set("place", { formatted_address: address });
          } else {
            console.error(
              "Geocode was not successful for the following reason:",
              status
            );
          }
        });
      }

      setAutocomplete(autocompleteInstance);
    }
  }, [geolocation]);

  useEffect(() => {
    if (inputRef.current && clearAddress) {
      inputRef.current.value = "";
      setClearAddress(false);
    }
  }, [clearAddress]);

  const success = useCallback(
    (pos) => {
      const crd = pos.coords;
      setGeolocation({ latitude: crd.latitude, longitude: crd.longitude });
      setGeolocationStatus({ status: false, message: "" });
    },
    [setGeolocation, setGeolocationStatus]
  );

  const error = useCallback(() => {
    setGeolocationStatus({ status: true, message: "error" });
    setTimeout(
      () => setGeolocationStatus({ status: false, message: "" }),
      5000
    );
  }, [setGeolocationStatus]);

  const checkGeolocationPermission = async () => {
    if (navigator.geolocation && !geolocationStatus.status) {
      setGeolocationStatus({ status: true, message: "loading" });
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      setGeolocationStatus({
        status: true,
        message: "Geolocation not supported"
      });
      setTimeout(
        () => setGeolocationStatus({ status: false, message: "" }),
        3000
      );
    }
  };

  const handleFocus = () => {
    if (!isFocused) {
      setIsFocused(true); // Встановлюємо, що поле вже було сфокусоване
      setStartTime(Date.now()); // Запускаємо час при першому фокусі
    }
    if (onFocus) {
      onFocus();
    }
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <div
        className={styles.addressContainer}
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative"
        }}
      >
        <label
          htmlFor="address"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            <p className={styles.addressLabel}>
              {!isMobile ? "Subject Address or Point of Interest" : "Address"}
            </p>

            <div className={styles.buttonBox}>
              <button
                className={styles.autocompleteMobileBtn}
                onClick={checkGeolocationPermission}
                disabled={isDisabled}
                style={
                  {
                    //padding: "5px 0"
                  }
                }
              >
                {geolocationStatus.status &&
                geolocationStatus.message === "loading" ? (
                  <Oval
                    height={15}
                    width={20}
                    color="rgb(25, 111, 175)"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                ) : (
                  <img src={LocationIcon} alt="location" />
                )}
                Use current location
              </button>
            </div>
          </div>

          <ShadowInput
            placeholder="Enter address"
            value=""
            onChange={(value) => setAddress(value)}
            onFocus={handleFocus}
            disabled={isDisabled}
            innerRef={inputRef}
          />

          {geolocationStatus.status && geolocationStatus.message === "error" && (
            <>
              {/* {!isMobile ? (
                <div
                  style={{
                    position: "absolute",
                    marginTop: `${isMobile ? "70px" : "75px"}`
                  }}
                >
                  <GeolocationMessage
                    geolocationStatusMessage={geolocationStatus.message}
                  />
                </div>
              ) : (
                <div>
                  <GeolocationMessage
                    geolocationStatusMessage={geolocationStatus.message}
                  />
                </div>
              )} */}
              <div>
                <GeolocationMessage
                  geolocationStatusMessage={geolocationStatus.message}
                />
              </div>
            </>
          )}
        </label>
      </div>
    </>
  );
};

CustomGoogleAutocomplete.propTypes = {
  clearAddress: PropTypes.bool.isRequired,
  setClearAddress: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.any,
  googleApiKey: PropTypes.any
};

export default CustomGoogleAutocomplete;
