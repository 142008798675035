/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as importedStyles from "./Captcha.module.scss";
import { Oval } from "react-loader-spinner";

const styles = importedStyles.default || importedStyles;

const Captcha = ({ setCaptchaVerified }) => {
  const [captcha, setCaptcha] = useState({ image: "", result: null });
  const [userAnswer, setUserAnswer] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setShowReloadButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Стан для відстеження завантаження капчі

  // Завантажуємо капчу при першому рендері компонента
  useEffect(() => {
    loadCaptcha();
  }, []);

  // Функція для завантаження капчі з сервера
  const loadCaptcha = async () => {
    try {
      setIsLoading(true); // Початок завантаження
      const { data } = await axios.get(
        "https://toolsapi-stage.rentometer.com:3000/captcha"
        //"http://localhost:4000/captcha"
      );
      setCaptcha({
        image: data.image,
        result: data.result
      });
      setErrorMessage("");
      setUserAnswer("");
      setShowReloadButton(false);
    } catch (error) {
      console.error("Error loading captcha", error);
    } finally {
      setIsLoading(false); // Завершення завантаження
    }
  };

  // Обробка відправлення відповіді користувача
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://toolsapi-stage.rentometer.com:3000/verify-captcha",
        //"http://localhost:4000/verify-captcha",
        {
          userAnswer: userAnswer.trim(),
          result: captcha.result
        }
      );
      if (data.success) {
        setCaptchaVerified(true);
      } else {
        setErrorMessage("Validation failed. Please try again.");
        setShowReloadButton(true);
      }
    } catch (error) {
      console.error("Error verifying captcha", error);
      setErrorMessage("An error occurred while verifying. Please try again.");
    }
  };

  return (
    <div className={styles.captchaContainer}>
      <h3 className={styles.captchaContainerTitle}>
        Please solve this test to confirm you are human:
      </h3>
      <div>
        <div className={styles.captchaContent}>
          {isLoading ? (
            <div
              className={styles.loaderBox}
              style={{
                minWidth: "150px",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Oval
                height={30}
                width={30}
                color="#d95d1a"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <img
              src={captcha.image}
              alt="captcha"
              className={styles.captchaImage}
            />
          )}
          <form onSubmit={handleSubmit} className={styles.captchaForm}>
            <input
              type="text"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="Enter result"
              className={styles.captchaInput}
              disabled={isLoading} // Блокуємо інпут під час завантаження або після невдалої валідації
              style={{
                width: "100%",
                border: errorMessage ? "1px solid #C24040" : ""
              }}
            />
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
            {errorMessage ? (
              <button
                type="button" // Використовуємо type="button", щоб не сабмітити форму
                className={styles.captchaButton}
                onClick={loadCaptcha} // Завантажуємо нову капчу при натисканні
              >
                Try again
              </button>
            ) : (
              <button
                type="submit"
                className={styles.captchaButton}
                disabled={isLoading} // Блокуємо кнопку під час завантаження капчі
              >
                Check
              </button>
            )}
          </form>
        </div>

        {/* <div className={styles.errorBox}>
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          {showReloadButton && (
            <button onClick={loadCaptcha} className={styles.reloadButton}>
              Try validation again
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Captcha;
