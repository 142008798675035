/* eslint-disable react/prop-types */
import React from "react";
import * as importedStyles from "./FormField.module.scss";

const styles = importedStyles.default || importedStyles;

const FormField = ({ content, width, minWidth, isActive, action }) => {
  return (
    <>
      <div
        className={`${styles.container} ${
          isActive ? styles.activeContainer : ""
        }`}
        style={{ width, minWidth }}
        onClick={action}
      >
        {content}
      </div>
    </>
  );
};

export default FormField;
