import { createStore, applyMiddleware } from "redux";
// import {configureStore as createStore} from '@reduxjs/toolkit'
import createSagaMiddleware from "@redux-saga/core";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./saga/index.saga";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();
const requestMiddleware = composeWithDevTools(applyMiddleware(sagaMiddleware));

export default function configureStore() {
  let store = createStore(rootReducer, requestMiddleware);
  sagaMiddleware.run(rootSaga);
  return { store };
}
