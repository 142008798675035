import { useEffect, useState } from "react";

export default function useDefaultJsScript(libraries, googleApiKey) {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=${libraries.join(
        ","
      )}&callback=Function.prototype`;
      script.async = true;

      script.onload = () => setIsScriptLoaded(true);

      document.body.appendChild(script);
    };

    loadScript(); // Call the async function to load the script

    return () => {
      const existingScript = document.querySelector(
        `script[src="https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=${libraries.join(
          ","
        )}&callback=Function.prototype"]`
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [libraries, googleApiKey]);

  return isScriptLoaded;
}
