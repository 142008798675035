/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApiKey, setApiHost, setCaptcha } from "../store/actions";
import { createPortal } from "react-dom";
import { resetRentAnalysis, RESET_ERROR } from "../store/actions";
import InputPage from "../pages/InputPage/InputPage";
import OutputPage from "../pages/OutputPage/OutputPage";
import * as importedStyles from "./Routes.module.scss";
import { FrameWrapper } from "../pages/FrameWrapper/FrameWrapper";
import useDefaultJsScript from "../hooks/useDefaultJsScript";
const styles = importedStyles.default || importedStyles;
const libraries = ["places"];

export const Routes = ({
  buttonTitle,
  apikey,
  apihost,
  enableCaptcha,
  enableEmailReporting,
  useAutocomplete,
  googleApiKey
}) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { analysisIsReady } = useSelector((state) => state.generalReducer);

  const [isShowCheckLoader, setIsShowCheckLoader] = useState(false);
  const dispatch = useDispatch();

  const clearError = () => {
    dispatch({
      type: RESET_ERROR
    });
  };

  useEffect(() => {
    if (modalIsOpen) {
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  }, [modalIsOpen]);

  const defaultKey = "AIzaSyAwopNugn6tNb-1hXDY_ORinP5wV16vTUo";

  if (useAutocomplete) {
    googleApiKey
      ? useDefaultJsScript(libraries, googleApiKey)
      : useDefaultJsScript(libraries, defaultKey);
  }

  // useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: googleApiKey
  //     ? googleApiKey
  //     : "AIzaSyAwopNugn6tNb-1hXDY_ORinP5wV16vTUo",
  //   libraries
  // });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(resetRentAnalysis());
    clearError();
  };

  useEffect(() => {
    dispatch(setApiKey(apikey));
    dispatch(setApiHost(apihost));
  }, [apikey, apihost]);

  useEffect(() => {
    if (enableCaptcha) {
      dispatch(setCaptcha());
    }
  }, [enableCaptcha]);

  useEffect(() => {
    if (isShowCheckLoader) {
      setTimeout(() => {
        setIsShowCheckLoader(false);
      }, 2000);
    }
  }, [isShowCheckLoader]);

  return (
    <div>
      <button className={styles.mainBtn} onClick={openModal}>
        {buttonTitle || "Rent Analysis"}
      </button>
      {modalIsOpen &&
        !isLoading &&
        createPortal(
          <FrameWrapper apikey={apikey} closeModal={closeModal}>
            <div className={styles.modal}>
              <div style={{ padding: "20px 0" }}>
                <div>
                  {analysisIsReady ? (
                    <OutputPage
                      closeModal={closeModal}
                      setIsShowCheckLoader={setIsShowCheckLoader}
                    />
                  ) : (
                    <InputPage
                      closeModal={closeModal}
                      isShowCheckLoader={isShowCheckLoader}
                      enableEmailReporting={enableEmailReporting}
                      useAutocomplete={useAutocomplete}
                      googleApiKey={googleApiKey}
                    />
                  )}
                </div>
              </div>
            </div>
          </FrameWrapper>,
          document.body
        )}
    </div>
  );
};
