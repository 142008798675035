import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";

// Bundled widget

const WidgetDivs = document.querySelectorAll(".quickview_widget");

WidgetDivs.forEach((div) => {
  ReactDOM.createRoot(div).render(
    <App
      apikey={div.dataset.apikey}
      buttonTitle={div.dataset.buttontitle}
      apihost={div.dataset.apihost}
      enableCaptcha={div.dataset.enablecaptcha === "true"}
      enableEmailReporting={div.dataset.enableemailreporting === "true"}
      useAutocomplete={div.dataset.enablegoogleautocomplete !== "false"}
      googleApiKey={div.dataset.googleapikey}
    />
  );
});
