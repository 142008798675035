// useEmailValidation.js
import { useState, useMemo } from "react";

const useEmailValidation = (userEmail) => {
  const [touched, setTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const error = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Show error if input was touched and has lost focus
    if (touched && !isFocused) {
      if (!userEmail) {
        return "Email address is required!";
      } else if (!emailRegex.test(userEmail)) {
        return "Invalid Email Address!";
      }
    }
    return "";
  }, [userEmail, touched, isFocused]);

  const handleFocus = () => {
    setTouched(true);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return { error, handleFocus, handleBlur, setTouched };
};

export default useEmailValidation;
