/**
 * Function to format the current date and time.
 *
 * This function generates the current date and time in the format MM/DD/YYYY HH:MM:SS.
 * Example output: 08/30/2024 10:28:45
 *
 * @returns {string} The formatted date and time string.
 */
export function formatDateTime() {
  const now = new Date();

  const padZero = (num) => (num < 10 ? "0" : "") + num;

  const month = padZero(now.getMonth() + 1);
  const day = padZero(now.getDate());
  const year = now.getFullYear();

  const hours = padZero(now.getHours());
  const minutes = padZero(now.getMinutes());
  const seconds = padZero(now.getSeconds());

  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}
